<template>
  <v-container fluid>
    <v-slide-x-transition mode="out-in">
      <div>
        <v-flex class="mt-5">
          <h1 class="mb-6">
            Global Settings
          </h1>
          <v-spacer />
          <v-card elevation="0" rounded="xl">
            <v-card-text>
              <v-row>
                <v-col>
                  <div style="padding: 0 10px 0 10px; border-radius: 5px;">
                    <v-switch
                      v-model="webShopStatus"
                      inset
                      label="Web shop enabled?"
                    />
                  </div>
                  <div style="color: gray; margin-left: 10px; position: relative; top: -10px;">
                    It disables/ enables homevision.se eshop (web shop)
                  </div>
                  <div style="padding: 0 10px 0 10px; border-radius: 5px;">
                    <v-switch
                      v-model="inAppShopStatus"
                      inset
                      label="In app shop enabled?"
                    />
                  </div>
                  <div style="color: gray; margin-left: 10px;  position: relative; top: -10px;">
                    It disables/ enables in APP shop (android/ iOS)
                  </div>
                </v-col>
                <v-col>
                  <div style="border: red solid 1px; padding: 0 10px 0 10px; border-radius: 5px; background: #fd595947;">
                    <div style="display: inline-block">
                      <v-switch
                        v-model="appMaintenanceMode"
                        inset
                        color="red"
                        label="App in maintenance mode?"
                      />
                    </div>
                    <div v-if="appMaintenanceMode" style="display: inline-block; position: relative; top: -5px; margin-left: 5px;">
                      <v-icon color="red">
                        mdi-alert
                      </v-icon>
                      <v-icon color="red">
                        mdi-alert
                      </v-icon>
                      <v-icon color="red">
                        mdi-alert
                      </v-icon>
                    </div>
                  </div>
                  <div style="color: gray; margin-left: 5px;">
                    It disables the app and displays maintenance mode in case of catastrophic scenario
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card
            v-if="$store.state.userEmail === 'ondrej@homevision.se'"
            class="mt-6"
            rounded="xl"
            elevation="0"
          >
            <v-card-title>
              Invite new admin
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="newAdminEmail"
                label="New Admin Email"
                type="email"
                dense
                outlined
                required
              />
              <v-text-field
                v-model="newAdminPassword"
                label="New Admin Password"
                type="text"
                dense
                outlined
                required
              />
              <v-btn :disabled="!newAdminEmail || !newAdminPassword" @click="inviteNewAdmin">
                Invite
              </v-btn>
            </v-card-text>
          </v-card>
        </v-flex>
      </div>
    </v-slide-x-transition>
  </v-container>
</template>

<script>
export default {
  name: 'GlobalSettings',
  components: {
  },
  data () {
    return {
      webShopStatus: false,
      originalValue: false,
      inAppShopStatus: true,
      appMaintenanceMode: false,
      appMaintenanceOriginalValue: false,
      newAdminPassword: '',
      newAdminEmail: ''
    }
  },
  watch: {
    webShopStatus: function (value) {
      this.enableOrDisableWebShop()
    }
  },
  mounted () {
    this.getWebShopStatus()
  },
  methods: {
    getWebShopStatus () {
      this.$store.dispatch('getWebShopStatus')
        .then(resp => {
          this.originalValue = resp.data.value
          this.webShopStatus = resp.data.value
        })
    },
    enableOrDisableWebShop () {
      if (this.originalValue !== this.webShopStatus) {
        this.$store.dispatch('updateWebShopStatus', { value: this.webShopStatus })
        this.originalValue = this.webShopStatus
      }
    },
    inviteNewAdmin () {
      this.$store.dispatch('inviteNewAdmin', { email: this.newAdminEmail, password: this.newAdminPassword })
        .then(resp => {
          this.newAdminEmail = ''
          this.newAdminPassword = ''
        })
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
